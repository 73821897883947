import { Suspense, type ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

export interface FallbackRenderProps {
    isDataError: boolean;
    items: any[];
    isLoading: boolean;
}

export function WSelectWrapper({
    fallback,
    children,
}: {
    fallback: (props: FallbackRenderProps) => ReactNode;
    children: ReactNode;
}) {
    return (
        <ErrorBoundary fallbackRender={(_props) => fallback({ items: [], isDataError: true, isLoading: false })}>
            <Suspense fallback={fallback({ items: [], isLoading: true, isDataError: false })}>{children}</Suspense>
        </ErrorBoundary>
    );
}
