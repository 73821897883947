import { Link } from "@tanstack/react-router";
import { ResourceWithIcon } from "../../components/ResourceWithIcon.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { vpcLink } from "./links.ts";
import type { VpcWithType } from "./vpc/apiOperations.ts";

export function VpcRow({ vpc }: { vpc: VpcWithType | undefined }) {
    return (
        <DetailsTableRow title="VPC network:">
            {vpc ? <ResourceWithIcon type="vpc" title={<Link {...vpcLink(vpc)}>{vpc.name}</Link>} /> : "–"}
        </DetailsTableRow>
    );
}
