import { IpAddressSelect, type IpAddressSelectSelectLoaderProps } from "../../modules/network/IpAddressSelect.tsx";
import { ClipBoardTooltip } from "../ClipBoardTooltip.tsx";
import { DetailsTableRow } from "./DetailsTableRow.tsx";

export interface DetailsTableIpAddressProps
    extends Pick<IpAddressSelectSelectLoaderProps, "value" | "location" | "isLoading" | "onChange"> {}

export function DetailsTableIpAddress({ ...props }: DetailsTableIpAddressProps) {
    const { value } = props;
    return (
        <DetailsTableRow title="Public IPv4:" tooltip="Public IP addresses are managed in the Network view.">
            <div className="flex items-center">
                <IpAddressSelect enableInlineEditing {...props} />
                {!!value && <ClipBoardTooltip text={value.address} />}
            </div>
        </DetailsTableRow>
    );
}
