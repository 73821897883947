import type { IpAddressWithType } from "./apiOperations.ts";
import { UNASSIGNED_KEY, UNASSIGNED_RESOURCE } from "./resourceId.ts";

export type AssignedIpAddress = IpAddressWithType;

export function getIpAddressKey(item: AssignedIpAddress | UNASSIGNED_RESOURCE): string {
    return item === UNASSIGNED_RESOURCE ? UNASSIGNED_KEY : item.address;
}

export function getIpAddressLabel(item: AssignedIpAddress | UNASSIGNED_RESOURCE): string {
    return item === UNASSIGNED_RESOURCE ? "Unassigned" : item.address;
}
