import LR from "../../components/LeftRight.module.css";

import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type ConfiguredWSelectProps } from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import { ResourceIcon } from "../../components/icon/ResourceIcon.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { UnassignedItem } from "./UnassignedItem.tsx";
import { ipQueryAtom } from "./ipAddress/apiOperations.ts";
import { getIpAddressKey, getIpAddressLabel, type AssignedIpAddress } from "./ipAddress/assignable.ts";
import { UNASSIGNED_RESOURCE } from "./ipAddress/resourceId.ts";

export interface IpAddressSelectProps
    extends ConfiguredWSelectProps<AssignedIpAddress | typeof UNASSIGNED_RESOURCE, string> {}

export function IpAddressSelectCustom(props: IpAddressSelectProps) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select IP address for the resource"
            getKey={getIpAddressKey}
            getTextValue={getIpAddressLabel}
            searchItems={(query, items) =>
                items.filter((item) => getIpAddressLabel(item).toLowerCase().includes(query.toLowerCase()))
            }
            {...props}
        >
            {(item) =>
                item !== UNASSIGNED_RESOURCE ? (
                    <LeftRight icon={<ResourceIcon item={item} />} title={item.address} right={item.name} />
                ) : (
                    <UnassignedItem />
                )
            }
        </WSelect>
    );
}

/** Exclude other `value` props since we need to access value below. */
export interface IpAddressSelectSelectLoaderProps
    extends Omit<IpAddressSelectProps, "items" | "defaultValue" | "valueKey" | "defaultValueKey"> {
    value: IpAddressSelectProps["value"];
    location: string;
}

function IpAddressSelectLoader({ location, ...props }: IpAddressSelectSelectLoaderProps) {
    const { value } = props;
    const data = useSuspenseQueryAtom(ipQueryAtom(location));
    // Only show free IPs and the currently selected IP.
    const unassigned = [...data.values()].filter((s) => s.uuid === value?.uuid || !s.assigned_to);

    return <IpAddressSelectCustom items={[UNASSIGNED_RESOURCE, ...unassigned]} {...props} />;
}

export function IpAddressSelect(props: IpAddressSelectSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <IpAddressSelectCustom {...props} {...renderProps} />}>
            <IpAddressSelectLoader {...props} />
        </WSelectWrapper>
    );
}
