import G from "./Grid.module.css";
import T from "./Tabs.module.css";

import { Children, useEffect, useRef, useState, type ReactNode } from "react";
import { Tab, TabList, TabPanel, Tabs, type Key, type TabsProps } from "react-aria-components";
import { cn } from "../utils/classNames.ts";
import { useLayoutSizeObserver } from "../utils/useLayoutSizeObserver.ts";
import { ActionMenu } from "./ActionMenu.tsx";
import { Separator } from "./Separator.tsx";
import { WButton } from "./button/WButton.tsx";
import { ContentLoadingSuspense } from "./loading/Loading.tsx";

export interface WTabItem {
    id: string;
    title: ReactNode;
    content: ReactNode;
}

export interface WTabsProps extends Pick<TabsProps, "aria-label"> {
    // Items
    items: WTabItem[];
    allTab?: boolean;
    /** "All" tab content */
    children?: ReactNode;

    // Slots
    button?: ReactNode;

    // Styles
    wideHeader?: boolean;
    compact?: boolean;

    // Utility
    /** Wrap all {@link items}' content in a {@link Suspense} */
    autoSuspense?: boolean;
}

function hide(el: HTMLElement, hidden = true) {
    el.style.visibility = hidden ? "hidden" : "visible";
}

export function WTabs({ allTab, children, items, button, wideHeader, autoSuspense, compact, ...props }: WTabsProps) {
    const ref = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const [currentVisibleCount, setVisibleCount] = useState<number>(Infinity);
    const [selected, setSelected] = useState<Key | null>(null);

    // Prepend the "All" tab to the list of tabs
    if (allTab) {
        const allContent = (
            <>
                {Children.count(children) > 0 && (
                    <>
                        {children}
                        <Separator />
                    </>
                )}
                {items.map((item, index) => (
                    // XXX: The grid style override here seems to specific - surely it's needed in other contexts besides "All Tabs" eg. all ContentPanes.
                    <div className={G.InAllTabs} key={item.id}>
                        {index !== 0 && <Separator />}
                        {item.content}
                    </div>
                ))}
            </>
        );

        items = [{ id: "__all__", title: "All", content: allContent }, ...items];
    }

    function updateVisibleTabs() {
        if (compact) return;

        const tabList = listRef.current;
        if (!tabList) {
            console.warn("No tab element found");
            return;
        }

        const tabs = tabList.children;
        const maxWidth = tabList.clientWidth;

        if (tabs.length === 0) {
            console.warn("No tabs found");
            return;
        }

        // Iterate through all tab elements one-by-one and stop when the right edge of the tab is beyond `maxWidth`.
        let visibleCount = 0;
        for (let i = 0; i < tabs.length; i++) {
            const tab = tabs[i] as HTMLElement;
            const endOffset = tab.offsetLeft + tab.offsetWidth;
            if (endOffset > maxWidth) {
                console.debug("Tab(%s): Does not fit into %s at offset %s", i, maxWidth, endOffset);
                hide(tab);

                // Hide remaining tabs
                for (; i < tabs.length; i++) {
                    hide(tabs[i] as HTMLElement);
                }

                break;
            } else {
                // Show previously hidden tabs (when they now fit)
                hide(tab, false);
            }

            visibleCount++;
        }

        if (visibleCount === 0) {
            console.warn("No tabs fit into %s", maxWidth);
            visibleCount = 1;
        }

        setVisibleCount((prev) => {
            if (prev !== visibleCount) {
                console.debug(
                    "Visible tabs: %s in width %s (out of %s rendered, %s existing)",
                    visibleCount,
                    maxWidth,
                    tabs.length,
                    items.length,
                );
            }
            return visibleCount;
        });
    }

    // NB: This monitors the width of the parent element, but the actual width used is the width of the inner TabList.
    // This way if the TabList changes size it does not cause an infinite loop. This is perhaps not necessary.
    useLayoutSizeObserver({
        ref,
        onResize(_rect, _el) {
            updateVisibleTabs();
        },
    });

    // Re-measure the tabs when the `items` change (based on only the count currently, to speed up the re-renders)
    useEffect(() => {
        // Use a timeout to make sure new tabs have been rendered (hopefully...) since react-aria seems to not render
        // them for the initial effect. Might not work with React concurrent mode.
        setTimeout(() => {
            updateVisibleTabs();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items.length]);

    const menuItems = items.slice(currentVisibleCount);

    return (
        <Tabs
            className={cn(T.Tabs, compact && T.compact)}
            aria-label="Tabs"
            selectedKey={selected}
            onSelectionChange={setSelected}
            {...props}
        >
            <div className={cn(T.TabHeader, wideHeader && T.Wide)} ref={ref}>
                <TabList ref={listRef} className={cn(T.TabList, wideHeader && T.Wide)}>
                    {items.map((item) => (
                        <Tab key={item.id} id={item.id} className={T.Tab}>
                            {item.title}
                        </Tab>
                    ))}
                </TabList>

                <div className={T.Right}>
                    {menuItems.length > 0 && (
                        <TabNavigation selectedKey={selected} setSelected={setSelected} items={menuItems} />
                    )}
                    {button}
                </div>
            </div>

            {items.map((item) => (
                <TabPanel key={item.id} id={item.id} className={T.TabPanel}>
                    {autoSuspense ? <ContentLoadingSuspense>{item.content}</ContentLoadingSuspense> : item.content}
                </TabPanel>
            ))}
        </Tabs>
    );
}

function TabNavigation({
    items,
    selectedKey,
    setSelected,
}: {
    items: WTabItem[];
    selectedKey: Key | null;
    setSelected: (key: Key) => void;
}) {
    const actions = items.map((item) => ({ id: item.id, title: item.title }));

    return (
        <ActionMenu
            onAction={({ id }) => setSelected(id)}
            items={actions}
            selectedKeys={selectedKey != null ? [selectedKey] : []}
        >
            <WButton
                color="primary"
                variant="ghost"
                size="bar"
                ariaLabel="Extra tabs"
                icon="jp-icon-ellipses"
                action={undefined}
                className={T.TabButton}
            />
        </ActionMenu>
    );
}
